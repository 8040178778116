<!--
 * @Description: 路内停车管理系统 运营管理 取证记录 forensicsRecords
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="forensicsRecordsmainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchForm.plateNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车位号">
              <el-input v-model="searchForm.parkSpaceNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="收费员编号">
              <el-input v-model="searchForm.operator"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="行为类型">
              <el-select v-model="searchForm.eventType"
                         placeholder="请选择">
                <el-option v-for="item in optionsType"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <!-- <el-col :span="6">
            <el-form-item label-width="90px"
                          label="岗位">
              <el-select v-model="searchForm.select2"
                         placeholder="请选择">
                <el-option v-for="item in options"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-form-item label-width="90px"
                        label="取证时间">
            <el-date-picker v-model="value2"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="['00:00:00', '23:59:59']"
                            :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-row>
      </el-form>
      <el-row class="topButton">
        <el-button type="primary"
                   @click="queryButton">查 询</el-button>
        <el-button type="info"
                   @click="resetForm"
                   style="margin-left:17px;">重 置</el-button>
      </el-row>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>取证记录</span>
        <el-button type="info"
                   class="tableTitleButton">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="isFirst">
            <!-- <el-table-column type="selection"></el-table-column> -->
          </template>
          <template slot="operating">
            <!-- <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="handleClick(scope.row)"
                             type="text"
                             size="medium">查看</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             size="medium">编辑</el-button>
                </el-col>
              </template>
            </el-table-column> -->
          </template>
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableColumnList: [
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'parkSpaceNumber',
          label: '车位号',
        },
        {
          prop: 'parkId',
          label: '停车场编号'
        },
        {
          prop: 'parkName',
          label: '停车场名称',
        },
        // {
        //   prop: 'id1',
        //   label: '收费员编号',
        // },
        // {
        //   prop: 'person',
        //   label: '收费员姓名',
        // },
        {
          prop: 'evidenceCreateTime',
          label: '取证时间',
        },
        {
          prop: 'eventTypeDesc',
          label: '行为类型',
        },
      ],
      tableList: {
        list: [
          // {
          //   id: '渝B90B44',
          //   name: '4021',
          //   name1: '龙山路停车场',
          //   id1: '40293',
          //   person: '王大军',
          //   time: '2021-02-23',
          //   type: '入场取证',
          // },
          // {
          //   id: '渝B58472',
          //   name: '4011',
          //   name1: '龙山路停车场',
          //   id1: '40293',
          //   person: '王大军',
          //   time: '2021-02-24',
          //   type: '入场取证',
          // },
          // {
          //   id: '渝B9731A',
          //   name: '3079',
          //   name1: '松石路停车场',
          //   id1: '40293',
          //   person: '李双勤',
          //   time: '2021-02-24',
          //   type: '入场取证',
          // },
        ],
      },
      searchForm: {
        plateNumber: '', //车牌号
        parkSpaceNumber: '', //车位号
        operator: '', //操作员编号
        eventType: '',//行为类型
        startTime: '', //取证时间范围查询
        endTime: ''//取证时间范围查询
      },
      //行为类型
      optionsType: [
        { label: '入场取证', value: 1 },
        { label: '巡检取证', value: 2 },
      ],
      options: [
        { label: '路段一临时停车', value: 1 },
        { label: '路段二临时停车', value: 2 },
      ],
      //时间选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      value1: '',
      value2: '',
      select: '',
      //分页查询
      pageSize: 15,
      pageNum: 1
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getTable()
  },
  //方法集合
  methods: {
    //查询表格数据
    getTable () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      console.log("查询到数据参数的入参", this.searchForm)
      this.$forensicsRecords
        .queryListByPage(this.searchForm)
        .then((response) => {
          console.log(response);
          this.tableList.list = response.resultEntity.list
          this.paginationConfig.total = response.resultEntity.total
        })
        .catch((error) => {
          console.log('获取数据错误', error)
        })
    },
    //查询按钮
    queryButton () {
      this.pageNum = 1
      this.getTable() //表格数据查询
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.getTable();
    },
    onSubmit () { },
    resetForm () {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
      }
      this.getTable()
    },
    handleClick () { },
    //斑马线
    tableRowClassName ({ rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row'
      } else if (rowIndex % 2 === 1) {
        return 'success-row'
      }
      return ''
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 185px;
//@import url(); 引入公共css类
//斑马线
/deep/.warning-row {
  background: #063159 !important;
}
/deep/.success-row {
  background: #032244 !important;
}
// 最外层div
.forensicsRecordsmainBody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
  }
  .tableContent {
    margin-top: 10px;
  }
}
</style>
